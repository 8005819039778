<script setup lang="ts">

import axios from "axios";
import {debounce, first, last, reduce, some, sortBy} from "lodash";
import qs from "qs";
import {Ref, ref, onBeforeUnmount} from "vue";
import {onBeforeRouteUpdate} from "vue-router";
import {useUserStore} from "../stores/UserStore";
import {CatalogFilters, Category, Price, PricesStats} from "../types";
import Flag from "./Flag.vue";
import Grade from "./Grade.vue";
import Battery from "./Battery.vue";
import Broadcaster from "../pusher";
import {Channel} from "pusher-js";
import DropdownFilter from "./DropdownFilter.vue";
import {useI18n} from "vue-i18n";
const {t} = useI18n();

const category: Ref<Category | null> = ref(null);
const list: Ref<Array<Array<Price>>> = ref([]);
const stats: Ref<PricesStats | null> = ref(null);
const itemsCount: Ref<number> = ref(0);
const filters: Ref<CatalogFilters | null> = ref(null);

const user = useUserStore();

const showModelsFilters: Ref<boolean> = ref(false);
const showBrandsFilters: Ref<boolean> = ref(false);
const showGradesFilters: Ref<boolean> = ref(false);
const showFlags: Ref<boolean> = ref(false);

const activeModelsFilters: Ref<Array<string>> = ref([]);
const activeBrandsFilters: Ref<Array<string>> = ref([]);
const activeGradesFilters: Ref<Array<string>> = ref([]);
const activeSearchFilter: Ref<string> = ref('');

const processingPrices: Ref<boolean> = ref(false);
const processingItems: Ref<boolean> = ref(false);

let page: number = 1;
let notificationChannel: Channel|null = null;

const props = defineProps<{
    id: number
}>();


const loadCategoryInformation = (id: number) => {
    if (user.profile?.categories === undefined) {
        return;
    }

    category.value = user.profile.categories.find((element) => Number(element.id) === Number(id)) ?? null;
}

const loadMorePrices = async (id: number) => {
     try {
        page ++;

        const queryString = qs.stringify(getQuery());
        const pricesResponse = await axios.get(`/api/prices/${id}?${queryString}`);

        list.value = list.value.concat(pricesResponse.data.items);
        showFlags.value = some(list.value, (prices: Array<Price>) => prices.length > 1);

    } catch (error) {
        console.error(error);
    }
}

const getQuery = () => {
    const query: {
        models?: string[],
        products?: string[],
        page?: number,
        brands?: string[],
        grades?: string[],
        search?: string,
    } = {}

    if (activeModelsFilters.value.length) {
        query.models = activeModelsFilters.value
    }

    if (activeBrandsFilters.value.length) {
        query.brands = activeBrandsFilters.value
    }

    if (activeGradesFilters.value.length) {
        query.grades = activeGradesFilters.value
    }

    if (activeSearchFilter.value) {
        query.search = activeSearchFilter.value;
    }

    query.page = page;

    return query;
}

const loadPrices = async (id: number) => {
    try {
        list.value = [];

        const queryString = qs.stringify(getQuery());
        const pricesResponse = await axios.get(`/api/prices/${id}?${queryString}`);

        itemsCount.value = pricesResponse.data.items_count;
        stats.value = pricesResponse.data.stats;
        list.value = pricesResponse.data.items;
        filters.value = pricesResponse.data.filters;

        // Note: possible bug
        showFlags.value = some(list.value, (prices: Array<Price>) => prices.length > 1);
    } catch (error) {
        console.error(error);
    }
}

const loadMore = async (event: Event) => {
    await loadMorePrices(props.id);
}

const onModelsFilterChange = async (event: Event) => {
    page = 1;
    await loadPrices(props.id);
}

const onProductsFilterChange = async (event: Event) => {
    page = 1;
    await loadPrices(props.id);
}

const onBrandsFilterChange = async (event: Event) => {
    activeModelsFilters.value = [];

    page = 1;
    await loadPrices(props.id);
}

const onGradesFilterChange = async (event: Event) => {
    page = 1;
    await loadPrices(props.id);
}

const onSearchInputChange = debounce(onProductsFilterChange, 500);

const getOnlineItems = (prices: Array<Price>) => {
    return reduce(prices,  (accumulator: number, price: Price) => accumulator + price.online_items, 0)
}

const isBuybackCatalog = () => {
    return user.configuration?.catalog_type === 'buyback';
}

const getTopCompetitor = (prices: Array<Price>) => {
    if (isBuybackCatalog()) {
        return last(sortBy(prices, (price: Price) => price.buyback_max_price));
    }

    return first(sortBy(prices, (price: Price) => price.resell_min_price));

};

const closeFilters = () => {
    showModelsFilters.value = false;
    showBrandsFilters.value = false;
    showGradesFilters.value = false;
}

onBeforeRouteUpdate(async (to, from) => {
    // only fetch the user if the id changed as maybe only the query or the hash changed
    if (to.params.id !== from.params.id) {
        resetFilters();
        loadCategoryInformation(Number(to.params.id));
        await loadPrices(Number(to.params.id));
    }
});

const resetFilters = () => {
    activeModelsFilters.value = [];
    activeBrandsFilters.value = [];
    activeGradesFilters.value = [];
    activeSearchFilter.value = '';
    page = 1;
}

const onResetFiltersBtnClick = async (event: Event) => {
    resetFilters();
    await loadPrices(props.id);
}

const getExportFilters = () => {
    const query: {
        models?: string[],
        products?: string[],
        brands?: string[],
        grades?: string[],
        search?: string,
    } = {}

    if (activeModelsFilters.value.length) {
        query.models = activeModelsFilters.value
    }

    if (activeBrandsFilters.value.length) {
        query.brands = activeBrandsFilters.value
    }

    if (activeGradesFilters.value.length) {
        query.grades = activeGradesFilters.value
    }

    if (activeSearchFilter.value) {
        query.search = activeSearchFilter.value;
    }

    return query;
}

const unsubscribe = () => {
    console.log('Unsubscribing...');
    Broadcaster.get().connector.unsubscribe(`private-user.${user.profile?.id}.notifications`);
    notificationChannel?.unbind('prices-export-completed');
    notificationChannel?.unbind('items-export-completed');
    notificationChannel = null;
}

const subscribe = () => {
    if (notificationChannel !== null) {
        return;
    }

    console.log('Subscribing...');
    notificationChannel = Broadcaster.get().connector.subscribe(`private-user.${user.profile?.id}.notifications`);
}

const download = (data: {filename: string}) => {
    console.log('Downloading..', data.filename);
    window.location.href = `/api/download/${data.filename}`;
}

const onPricesExportBtnClick = async () => {
    try {
        processingPrices.value = true;

        notificationChannel?.bind('prices-export-completed', (data: {filename: string}) => {
            console.log('Event received', data);
            processingPrices.value = false;
            download(data);
        });

        await axios.post(`/api/prices/${props.id}/export`, getExportFilters());

    } catch (error) {
        // unsubscribe();
    }
}

const onItemsExportBtnClick = async () => {
    try {
        processingItems.value = true;

        notificationChannel?.bind('items-export-completed', (data: {filename: string}) => {
            console.log('Event received', data);
            processingItems.value = false;
            download(data);
        });

        await axios.post(`/api/prices/${props.id}/items/export`, getExportFilters());

    } catch (error) {
        // unsubscribe();
    }
}

onBeforeUnmount(() => unsubscribe());

subscribe();
loadCategoryInformation(props.id);
loadPrices(props.id);


</script>

<template>

    <h2 class="text-4xl font-bold text-white flex items-center">
        {{ t('prices.title', {category: category?.name}) }}
    </h2>

    <div class="mt-12 shadow-md">
        <!-- Header -->
        <div class="relative bg-gray-800 rounded-t-lg">

            <div class="flex justify-between items-end mx-4 px-4 py-8 border-b border-gray-600">
                <div class="flex flex-row space-x-12 text-white items-center">
                    <div>
                        <dt class="mb-1 text-3xl font-extrabold">{{ stats?.products }}</dt>
                        <dd class="text-xs font-bold text-gray-400 uppercase">{{ t('prices.models') }}</dd>
                    </div>
                    <div>
                        <dt class="mb-1 text-3xl font-extrabold">{{ stats?.monitored_items }}</dt>
                        <dd class="text-xs font-bold text-gray-400 uppercase">{{ t('prices.monitoredDeals') }}</dd>
                    </div>
                </div>

                <div>
                    <span class="text-gray-500 text-xs">{{ t('common.updatedAt', {updatedAt: stats?.last_update}) }}</span>
                </div>
            </div>

            <!-- Filters & Buttons -->
            <div class="flex flex-row justify-between items-center p-4">

                <!-- Filters -->
                <div class="flex flex-row space-x-2 items-center">

                    <!-- Brands Filter -->
                    <DropdownFilter
                        v-model:value="activeBrandsFilters"
                        :options="filters?.brands || []"
                        @update:value="onBrandsFilterChange"
                        :show="!!filters?.brands.length"
                        :buttonLabel="t('common.filters.byBrand')"
                        optionsLabel="Brands"
                    />

                    <!-- Models Filter -->
                    <DropdownFilter
                        v-model:value="activeModelsFilters"
                        :options="filters?.models || []"
                        @update:value="onModelsFilterChange"
                        :show="!!filters?.models.length"
                        :buttonLabel="t('common.filters.byModels')"
                        :optionsLabel="t('common.models')"
                    />

                    <!-- Grades Filter -->
                    <DropdownFilter
                        v-model:value="activeGradesFilters"
                        :options="filters?.grades || []"
                        @update:value="onGradesFilterChange"
                        :show="!!filters?.grades.length"
                        :buttonLabel="t('common.filters.byGrade')"
                        :optionsLabel="t('common.grades')"
                    />

                    <!-- Search Filter -->
                    <div>
                        <input v-model="activeSearchFilter" @input="onSearchInputChange" type="text" class="border border-gray-500 bg-gray-700 focus:outline-none focus:border-blue-500 text-white px-4 py-2.5 text-sm font-medium rounded-lg" :placeholder="t('common.filters.search')" />
                    </div>

                    <a href="#" class="inline-block pl-2 text-xs text-gray-300 underline" @click.prevent="onResetFiltersBtnClick">{{ t('common.filters.resetBtn') }}</a>

                </div>

                <!-- Buttons -->
                <div class="flex flex-row gap-2 justify-between">

                    <button @click.prevent="onPricesExportBtnClick" :disabled="processingPrices || processingItems" class="outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center text-gray-900 bg-gray-100 hover:bg-gray-200" type="button">
                        {{ t('common.exportToCsv') }}

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 ml-2" v-if="processingPrices === false">
                            <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
                            <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
                        </svg>

                        <span class="loader ml-2" v-else />

                    </button>

                    <button @click.prevent="onItemsExportBtnClick" :disabled="processingPrices || processingItems"  class="outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center text-gray-900 bg-gray-100 hover:bg-gray-200" type="button" v-if="user.configuration?.export_items_enabled">
                        {{ t('common.exportToCsv') }} Items

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 ml-2" v-if="processingItems === false">
                            <path d="M8.75 2.75a.75.75 0 0 0-1.5 0v5.69L5.03 6.22a.75.75 0 0 0-1.06 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-1.06-1.06L8.75 8.44V2.75Z" />
                            <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
                        </svg>

                        <span class="loader ml-2" v-else />
                    </button>

                </div>
            </div>

            <!-- Table -->
            <div class="relative overflow-x-auto bg-gray-800">
                <table class="w-full text-sm text-left text-gray-400">
                    <thead class="text-xs uppercase bg-gray-700 text-gray-400">
                        <tr>
                            <th scope="col" class="px-2 py-3">{{ t('prices.table.headings.product') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('prices.table.headings.grade') }}</th>
                            <th scope="col" class="px-2 py-3 text-nowrap"># Online</th>
                            <th scope="col" class="px-2 py-3 text-nowrap">Top competitor</th>
                            <th scope="col" class="px-2 py-3">
                                <div class="flex items-center">
                                    <div :class="[showFlags ? 'w-[122px]' : 'w-[112px]']">Prices</div>
                                    <div class="w-32">Min</div>
                                    <div class="w-32">Max</div>
                                    <div class="w-32">Avg</div>
                                    <div class="w-32">30 days change</div>
                                    <div class="w-32">60 days change</div>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(prices, index) in list" class="border-gray-600 hover:bg-gray-700 border-b" >
                            <td class="px-4 py-3 max-w-[420px]">
                                <div class="font-medium whitespace-nowrap text-white truncate">{{ prices[0].item_name }}</div>
                                <div class="flex">
                                    <span class="text-xs font-medium mr-4" v-if="prices[0].item_storage">{{prices[0].item_storage}}</span>
                                    <Battery :status="prices[0].battery_type" />
                                </div>
                            </td>

                            <td class="px-2 py-3">
                                <Grade :grade="prices[0].item_grade" />
                            </td>

                            <td class="px-2 py-3">
                                <div  class="text-xs font-medium flex items-center">
                                    <span class="mr-2"> {{ getOnlineItems(prices) }}</span>

                                    <svg v-if="getOnlineItems(prices) >= 20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-3 h-auto text-orange-400" fill="currentColor">
                                        <path d="M414.9 161.5C340.2 29 121.1 0 121.1 0S222.2 110.4 93 197.7C11.3 252.8-21 324.4 14 402.6c26.8 59.9 83.5 84.3 144.6 93.4-29.2-55.1-6.6-122.4-4.1-129.6 57.1 86.4 165 0 110.8-93.9 71 15.4 81.6 138.6 27.1 215.5 80.5-25.3 134.1-88.9 148.8-145.6 15.5-59.3 3.7-127.9-26.3-180.9z"/>
                                    </svg>
                                </div>
                            </td>

                            <td class="px-2 py-3">
                                <div class="text-xs text-gray-300">{{ getTopCompetitor(prices)?.item_seller_name  }}</div>
                                <div class="text-xs font-medium text-gray-500">{{ getTopCompetitor(prices)?.vendor_name }}</div>
                            </td>

                            <td class="px-2 font-medium whitespace-nowrap text-white">
                                <div class="divide-y divide-gray-600">

                                    <div v-for="(price, index) in prices" class="flex items-center py-3">

                                        <Flag :country="price.country" :visible="showFlags" :size="10" />

                                        <div>

                                            <div class="flex items-center" v-if="! isBuybackCatalog()">

                                                <div class="w-24 font-bold text-xs uppercase text-right pr-4 text-gray-600">Resell</div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300" v-if="price.resell_min_price">{{ price.currency_symbol }} {{ price.resell_min_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32 ">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300" v-if="price.resell_max_price">{{ price.currency_symbol }} {{ price.resell_max_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300" v-if="price.resell_average_price">{{ price.currency_symbol }} {{ price.resell_average_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">

                                                        <span v-if="price.resell_last30_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                                            <svg v-if="price.resell_last30_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                                            </svg>

                                                            <svg v-if="price.resell_last30_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                                            </svg>

                                                            {{ price.resell_last30_days_price_change}}%
                                                        </span>

                                                        <span class="text-xs text-gray-300" v-if="!price.resell_last30_days_price_change">-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">

                                                        <span v-if="price.resell_last60_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                                            <svg v-if="price.resell_last60_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                                            </svg>

                                                            <svg v-if="price.resell_last60_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                                            </svg>

                                                            {{ price.resell_last60_days_price_change}}%
                                                        </span>

                                                        <span class="text-xs text-gray-300" v-if="!price.resell_last60_days_price_change">-</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="flex items-center">

                                                <div class="w-24 text-xs font-bold uppercase text-right pr-4 text-gray-600">Trade In</div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300" v-if="price.buyback_min_price">{{ price.currency_symbol }} {{ price.buyback_min_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32 ">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300" v-if="price.buyback_max_price">{{ price.currency_symbol }} {{ price.buyback_max_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">
                                                        <span class="text-xs text-gray-300"  v-if="price.buyback_average_price">{{ price.currency_symbol }} {{ price.buyback_average_price }}</span>
                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">

                                                        <span v-if="price.buyback_last30_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                                            <svg v-if="price.buyback_last30_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                                            </svg>

                                                            <svg v-if="price.buyback_last30_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                                            </svg>

                                                            {{ price.buyback_last30_days_price_change}}%
                                                        </span>

                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                                <div class="w-32">
                                                    <p class="text-xs font-medium text-gray-500">

                                                        <span v-if="price.buyback_last60_days_price_change" class="text-gray-300 text-xs font-medium inline-flex items-center">
                                                            <svg v-if="price.buyback_last60_days_price_change < 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                                                            </svg>

                                                            <svg v-if="price.buyback_last60_days_price_change > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 mr-1">
                                                              <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
                                                            </svg>

                                                            {{ price.buyback_last60_days_price_change}}%
                                                        </span>

                                                        <span class="text-xs text-gray-300" v-else>-</span>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Load more -->
            <div class="relative overflow-hidden rounded-b-lg bg-gray-800">
                <nav class="flex flex-row items-center justify-between p-4" aria-label="Table navigation">
                    <div class="text-sm">
                        <p class="font-semibold text-white">
                            {{ list.length }} <span class="font-normal text-gray-400">{{ t('common.of') }}</span> {{ itemsCount }}
                        </p>
                    </div>
                    <button type="button" class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-blue-700 hover:bg-blue-800" @click="loadMore" v-if="list.length < itemsCount">
                        Load more
                    </button>
                </nav>
            </div>

        </div>
    </div>
</template>
